<template>
  <div class="card ">
    <div v-if="loading" class="card-body">
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
    <template v-else>
      <div class="card-body p-sm-3">
        <b-alert
          :variant="msg.type"
          dismissible
          class="mt-3"
          v-model="msg.has"
          :show="msg.text"
          >{{ msg.text }}</b-alert
        >

        <form @submit.prevent="handleSubmit" novalidate>
          <!-- name -->
          <div class="form-group mb-2">
            <label for="login" class="required">{{ $t("form.name") }}</label>
            <input
              class="form-control"
              v-model="form.name"
              id="login"
              :placeholder="$t('form.name-placeholder')"
              :readonly="type === 'view'"
              :class="[
                {
                  'is-invalid': submitted && $v.form.name.$error,
                },
                type === 'view' ? 'form-control-plaintext' : 'form-control',
              ]"
            />
            <div
              v-if="submitted && $v.form.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.name.required">{{
                $t("form.name-req")
              }}</span>
            </div>
          </div>

          <!-- CREATE button -->
          <div v-if="type === 'create'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/cities" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.cancel") }}
              </router-link>
              <button
                :disabled="inprogress"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                type="submit"
              >
                {{ $t("city.add") }}
              </button>
            </div>
          </div>

          <!-- EDIT button -->
          <div v-if="type === 'edit'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/cities" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.cancel") }}
              </router-link>
              <button
                :disabled="inprogress"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                type="submit"
              >
                {{ $t("btn.save") }}
              </button>
            </div>
          </div>

          <!-- VIEW button -->
          <div v-if="type === 'view'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/cities/" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.back") }}
              </router-link>
              <router-link
                :to="`/cities/edit/${$route.params.id}`"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                >{{ $t("btn.edit") }}</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    type: {
      type: String, // create, edit, view
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      submitted: false,
      inprogress: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
      form: {
        name: "",
      },
      formInit: null,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    formRules() {
      let rules = {};

      rules.name = {
        required,
      };

      return rules;
    },
  },
  methods: {
    loadData() {
      try {
        if (this.type === "edit" || this.type === "view") {
          this.getCity();
        }

        this.loading = false;
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async getCity() {
      this.loading = true;

      await axios
        .get(this.$urls.URL_CITIES + "/" + this.$route.params.id)
        .then((response) => {
          console.log(
            `response, city id (${this.$route.params.id}): `,
            response
          );

          this.fillInitData(response);
          this.form = { ...this.formInit };
          this.loading = false;
        })
        .catch((error) => {
          console.log("getCity Error", error);
        });
    },
    fillInitData(response) {
      this.formInit = {
        name: response.data.name,
      };
    },
    async handleSubmit() {
      this.submitted = true;
      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.inprogress = true;

        const formData = new FormData();

        // form
        formData.append("name", this.form.name);

        // Display the key/value pairs ###debug
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + " :", pair[1]);
        // }

        if (this.type === "create") {
          try {
            const response = await axios.post(this.$urls.URL_CITIES, formData, {
              headers: {
                "content-type": "multipart/form-data",
              },
            });
            // console.log("response: ", response);

            this.msg.has = true;
            this.msg.type = "success";
            this.msg.text = this.$t("city.msg-add-success");

            this.inprogress = false;
          } catch (error) {
            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message || this.$t("city.msg-edit-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          }
        }

        if (this.type === "edit") {
          const formData = new FormData();
          formData.append("_method", "PATCH");
          formData.append("name", this.form.name);

          try {
            const response = await axios.post(
              this.$urls.URL_CITIES + "/" + this.$route.params.id,
              formData
            );
            // console.log("response: ", response);

            this.msg.has = true;
            this.msg.type = "success";
            this.msg.text = this.$t("city.msg-edit-success");

            this.inprogress = false;
          } catch (error) {
            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message || this.$t("city.msg-edit-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 180px;
}

.form-control-plaintext {
  background: transparent;
  border: none;
  color: #000;
}
</style>
